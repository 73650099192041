<template>
  <div class="content">
    <PageHeader title="Escolha o tipo de campanha" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-2">
          <img class="mascot" src="../assets/mascote-2.svg">
        </div>
        <div class="col-10">
          <div class="row">
            <div class="col-lg-4">
              <div class="card">
                <div class="card-header">
                  SMS
                </div>
                <div class="card-body block-el">
                  <i class="fas fa-sms"></i>
                  <router-link
                    class="btn btn-success"
                    type="button"
                    to="/sms/campaigns"
                  >
                    Visualize e crie campanhas SMS
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-header">
                  E-mail <span class="badge badge-success">beta</span>
                </div>
                <div class="card-body block-el">
                  <i class="far fa-envelope"></i>
                  <router-link
                    class="btn btn-success"
                    type="button"
                    to="/mail/campaigns"
                  >
                    Visualize e crie campanhas SMS
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card soon">
                <div class="card-header">
                  Whatsapp
                </div>
                <div class="card-body block-el">
                  <i class="fab fa-whatsapp"></i>
                  <span class="soon-text">Em breve</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card soon">
                <div class="card-header">
                  RCS
                </div>
                <div class="card-body block-el">
                  <i class="far fa-comment-alt"></i>
                  <span class="soon-text">Em breve</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card soon">
                <div class="card-header">
                  Telegram
                </div>
                <div class="card-body block-el">
                  <i class="fab fa-telegram-plane"></i>
                  <span class="soon-text">Em breve</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card soon">
                <div class="card-header">
                  {{$t('generic-str.voice')}}
                </div>
                <div class="card-body block-el">
                  <i class="fas fa-microphone-alt"></i>
                  <span class="soon-text">Em breve</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Campaigns',
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
.mascot {
  position: sticky;
  top: 150px;
  max-width: 180px;
  display: block;
  margin: 0 auto;
}

.card.soon {
  opacity: .6;
  pointer-events: none;
  box-shadow: none !important;
}

.card-header .badge {
  float: right;
}

.card-body {
  padding: 40px 0;
  height: 140px;
  overflow: hidden;
  position: relative;

  a, .soon-text {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 11;
    display: block;
    margin: 0 auto;
    padding: 50px 20px;
    white-space: normal;
    height: 140px !important;
    width: 100% !important;

    &, &:hover, &:focus {
      background: transparent !important;
      color: inherit !important;
      border: none !important;
    }
  }

  i {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    font-size: 80pt;
    color: #e8e8e8;
  }
}
</style>
